import { Button } from "shared/components";
import breakpoints from "shared/utils/breakpoints";
import { color } from "shared/utils/styles";
import styled from "styled-components";

type PropT = {
  active?: boolean;
};

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${breakpoints.device.max.xs} {
    flex-direction: column;
  }
`;

export const Content=styled.div`
  background: #ffffff;
  border-radius: 18px;
  width: 100%;
  height: 100%;
  padding: 34px;
  margin-bottom: 10px;
`

export const LeftContainer = styled.div`
  width: 59%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
  }
`;

export const LeftTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.32);
  padding-bottom: 13px;
  margin-bottom: 37px;
`;

export const LeftTitleAmount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top:10px;
`;
export const LeftTitleRevalidate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;
`;

export const RightContainer = styled.div`
  width: 40%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  @media ${breakpoints.device.max.xs} {
    width: 100%;
    margin-top: 20px;
  }
`;


export const RightTopContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding-bottom: 13px;
  margin-bottom: 0px;
`;

export const RightTopInner = styled.div<PropT>`
  cursor: pointer;
  border-bottom: ${(props) => (props.active ? "3px" : "1px")} solid
    rgba(0, 0, 0, 0.32);
  border-color: ${(props) => props.active && "#760000"};
  color: ${(props) => props.active && "#760000"};
  padding: 10px;
  padding-right: 50px;
  font-weight: ${(props) => props.active && "bold"};
`;
export const RightTopInnerRem = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.32);
  padding: 10px;
  padding-right: 0px;
  width: calc(100% - 100px);
`;


export const InnerRight = styled.div`
  background: #ffffff;
  border: 0.5px dashed rgba(130, 130, 130, 0.9);
  border-radius: 10px;
  padding: 18px;
`;

export const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const LeftDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
`;
export const LeftTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(130, 130, 130, 0.9);
`;
export const LeftValue = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #292929;
`;

export const LSection = styled.div`
  margin: 30px 0 30px 45px;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
  @media ${breakpoints.device.max.xl} {
    margin: 20px 15px 30px 15px;
  }
`;

export const RSection = styled.div`
  display: grid;

  grid-template-columns: 45% 45%;
  row-gap: 30px;
  column-gap: 5px;
  height: 100%;
  margin: 10px 0px 10px 10px;
  @media ${breakpoints.device.max.xl} {
    margin: 10px 0px 10px 0px;
  }
`;
export const RightDiv = styled.div``;
export const RightTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(130, 130, 130, 0.9);
`;
export const RightValue = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

export const TitleCase = styled.div`
  width: 100%;
  height: 21px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #292929;
  margin-bottom: 10px;
`;
export const ButtonDiv = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ButtonContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;
export const Back = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  margin-top: 16px;
  cursor: pointer;

  /* Grey Text 1 */

  color: #828282;
`;

export const ActionButton = styled(Button)`
  margin: 1em 0;
  color: #fff;
  width: 138px;
  height: 54px;
  float: right;
  background-color: ${color.fountain};
`;

export const Divider = styled.div`
  border: 1px solid rgba(215, 215, 215, 0.58);
`;
export const MiniChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 0;
`;
export const MiniChartSection = styled.div`
  width: 50%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;
export const MiniItem = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const MiniItemSection = styled.div`
  padding-right: 5px;
`;
export const MiniItemSectionUp = styled.div`
  text-transform: uppercase;
  color: #292929;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: fit-content;
  padding-top: 3px;
`;
export const MiniItemSectionDown = styled.div`
  color: rgba(130, 130, 130, 0.9);
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
`;

export const LogSectionContainer = styled.div`
  border-top: 1px solid rgba(215, 215, 215, 0.58);
`;
export const LogSection = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: flex-start;
`;
export const LogSectionLeft = styled.div`
  padding-right: 10px;
  padding-top: 11px;
  padding-bottom: 5px;
  text-align: left;
  border-right: 1px dashed #000;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
`;
export const LogSectionRight = styled.div`
  padding-bottom: 5px;
  padding-top: 11px;
  margin-left: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;
export const IconImage = styled.img`
  position: relative;
  right: -14px;
  height: 7px;
`;

export const LeftRightIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #219653;
  flex-direction: row;
`;

export const RoundClock = styled.div`
  height: 150px;
  width: 150px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  border: 1px dashed #ccc;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  margin-bottom: 10px;

  text-align: center;

  /* Grey Text 1 */

  color: rgba(130, 130, 130, 0.9);
  flex-direction: column;
`;

export const ClockSpentMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-align: center;

  color: #760000;
`;

export const MinutesDiv = styled.div`
  font-weight: 400;
  font-size: 10px;
  text-align: center;
  line-height: 10px;
`;


export const SettlementDiv= styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Title=styled.div`
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  padding-bottom: 14px;
`
import React, { useEffect, useState } from "react";
import {
  ApiActionButtons,
  ApiContainer,
  ApiContent,
  ApiPageSubHeader,
  KeyDetails,
  KeyRow,
  KeyWithIcon,
  ModeSpan,
} from "./Styles";
import { Icon, Switch } from "shared/components";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import consoleLog from "shared/utils/consoleLog";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import { setService } from "store/actions/serviceAction";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import useServiceDetails from "shared/hooks/useServiceDetails";
import { regenerateApiKeys, toogleServiceMode } from "Services/serviceClass";
import { copyToClipboard } from "shared/utils/browser";
import useServiceMode from "shared/hooks/useServiceMode";

export default function Apikeys() {
  const service = useSelector((store: RootStateOrAny) => store.service);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const business = useSelector((store: RootStateOrAny) => store.business);
  const [loadLive, setloadLive] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [clickAction, setclickAction] = useState("");
  const [LinkCopied, setLinkCopied] = useState(false);
  const [isPrivateKeyVisible, setIsPrivateKeyVisible] = useState(false);
  const [isPublicKeyVisible, setIsPublicKeyVisible] = useState(false);

  const { serviceDetails, getServiceDetails } = useServiceDetails();
  const { serviceMode, loadingMode, toogleMode } = useServiceMode(service);

  const [live, setlive] = useState(service.mode === "TEST" ? false : true);
  const [test, settest] = useState(false);
  const dispatch = useDispatch();

  const { privateKey, publicKey, mode } = service;

  const handleResetKeys = async () => {
    setclickAction("Regen");
    setLoading(true);
    try {
      if (!shouldPerform(getRoleName(currentUser, business?.id), "ManAPI")) {
        setLoading(false);
        setclickAction("");
        return;
      }
      let response = await regenerateApiKeys(service?.id, {
        businessId: service?.businessId,
        serviceId: service?.id,
      });

      if (response.status === true && response.payload) {
        let { testPrivateKey, testPublicKey } = response.payload;
        // // console.log("response", response?.payload);
        await getServiceDetails(); //call function from custom hook
        toast.success(response.message ?? "Successfully Reset");
        setclickAction("");
        setLoading(false);
      } else {
        toast.error(response.message ?? "Unable to reset keys");
        setLoading(false);
      }
    } catch (err) {
      setclickAction("");
      setLoading(false);
      consoleLog("RESET error:", err);

      // @ts-ignore
      toast.error(err);
    } finally {
      setclickAction("");
      setLoading(false);
    }
  };

  const handleLinkCopy = (text: string) => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
    let useString = text && text.trim() !== "" ? text : " ";
    copyToClipboard(useString);
    toast.success("Copied");
  };
  

  return (
    <div>
      <ApiContainer>
        <h1>
          Api Keys <ModeSpan mode={service.mode=='LIVE'?true: false}>{mode}</ModeSpan>
        </h1>
        <ApiPageSubHeader>
          <p>Find your private and public api keys here</p>
          <ApiActionButtons>
            <button
              onClick={() => {
                handleResetKeys();
              }}
            >
              {Loading && clickAction === "Regen"
                ? "Processing.."
                : " Regenerate key"}
            </button>
            <div>
              <Switch
                id={"SubAction"}
                status={serviceMode}
                label={loadingMode ? "Loading" : live ? "Live" : "Test"}
                toggleMethod={() => toogleMode()}
              />
            </div>
          </ApiActionButtons>
        </ApiPageSubHeader>

        <ApiContent>
          <KeyRow>
            <KeyDetails>
              <h4>Public Key</h4>
              <KeyWithIcon>
                {!isPublicKeyVisible && <span>****************************************</span>}
                {isPublicKeyVisible && <span>{service.publicKey}</span>}
                <Icon
                    style={{ marginTop: "7px" }}
                    type={!isPublicKeyVisible?"eye-blocked":"eye"}
                    size="18"
                    color="#ccc"
                    isHover={true}
                    onClick={() => setIsPublicKeyVisible(!isPublicKeyVisible)}
                  />
              </KeyWithIcon>
              {/* <span>{publicKey}</span> */}
            </KeyDetails>
            <button onClick={() => handleLinkCopy(publicKey)}>Copy</button>
          </KeyRow>

          <KeyRow>
            <KeyDetails>
              <h4>Private Key</h4>
              <KeyWithIcon>
                {!isPrivateKeyVisible && <span>****************************************</span>}
                {isPrivateKeyVisible && <span>{service.privateKey}</span>}
                <Icon
                    style={{ marginTop: "7px" }}
                    type={!isPrivateKeyVisible?"eye-blocked":"eye"}
                    size="18"
                    color="#ccc"
                    isHover={true}
                    onClick={() => setIsPrivateKeyVisible(!isPrivateKeyVisible)}
                  />
                </KeyWithIcon>
              {/* <span>{privateKey}</span> */}
            </KeyDetails>
            <button onClick={() => handleLinkCopy(privateKey)}>Copy</button>
          </KeyRow>
        </ApiContent>
      </ApiContainer>
    </div>
  );
}

const summaryData = (rowCount: number, columnHeaders: any, data: any) => {
  if (rowCount === 0) return [];
  let resArr: any = [];
  let nArr: any = [];
  for (let el of columnHeaders) {
    nArr.push(el.label);
  }
  resArr.push(nArr);
  for (let el of data as any) {
    nArr = [];
    for (let elm of columnHeaders) {
      let val = elm?.id;
      if (val && el[val]) nArr.push(el[val]);
    }
    resArr.push(nArr);
  }

  return resArr;
};

export default summaryData;

import consoleLog from "shared/utils/consoleLog";
import AmountPanel from "./AmountPanel";
import Channels from "./Channels";
import GraphArea from "./GraphArea";
import PaymentLink from "./PaymentLink";
import PendingSettlement from "./PendingSettlement";
import SettlementPanel from "./SettlementPanel";
import RecentSettlement from "./RecentSettlement";
import {
  BottomLayoutPanel,
  Btn,
  HeaderBtnContainer,
  Layout,
  LeftLayoutPanel,
  PanelTitle,
  Panels,
  RightLayoutPanel,
  TopBtnsContainer,
} from "./Style";
import { useEffect, useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SeriviceHeadTabs from "./HeadTabs/HeadTabs";
import RecentTransactions from "./Transactions";
import useServiceDetails from "shared/hooks/useServiceDetails";
import { CardTray } from "Overview/Styles";
import OverviewTopCard from "shared/components/OverviewTopCard";

let date = new Date();
let currentDay = String(date.getDate()).padStart(2, "0");
let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
let currentYear = date.getFullYear();

let endsDate = `${currentYear}/${currentMonth}/${currentDay}`;

date.setUTCDate(date.getUTCDate() - 7);

const last7Days = `${date.getUTCFullYear()}/${(date.getUTCMonth() + 1)
  .toString()
  .padStart(2, "0")}/${date
  .getUTCDate()
  .toString()
  .padStart(2, "0")}`;

const Overview = () => {
  const [startDate, setStartDate] = useState(last7Days);
  const [endDate, setEndDate] = useState(endsDate);

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const service = useSelector((store: RootStateOrAny) => store.service);
  const [globalAction, setGlobalAction] = useState(false);

  const [btnName, setbtnName] = useState("day");
  const [IndicatorLeft, setIndicatorLeft] = useState<any>("0");

  const business = useSelector((store: RootStateOrAny) => store.business);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const { serviceDetails } = useServiceDetails();

  const ref: any = useRef(null);

  //consoleLog("Service details: ", service)
  let live = service?.mode === "LIVE";

  let topCardArray = [
    {
      icon: "link", //DocumentationIcon,
      text: "Payment links",
      link: `/my-apps/${service?.id}/payment-link-list`,
      disabled: false,
    },
    {
      icon: "bar-chart", //Tour,
      text: "Transactions",
      link: `/my-apps/${service?.id}/transactions`,
      disabled: false,
    },
    {
      icon: "card", //KnowledgeBaseIcon,
      text: "Settlements",
      link: `/my-apps/${service?.id}/settlement`,
      disabled: false,
    },

    {
      icon: "compass", //DocumentationIcon,
      text: "Mandate management",
      link: `/my-apps/${service?.id}/mandate-management`,
      disabled: false,
    },
  ];

  const handleToggleMode = async (setMode = "") => {
    try {
    } catch (error) {
      // setLive(!live);
    } finally {
      // await dispatch(setService({ ...service, mode }));
      consoleLog("2MODE");
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.firstElementChild?.nextSibling?.scrollIntoView();
    }
  }, []);


  useEffect(() => {
    if (btnName) {
      date = new Date();
      if (btnName == "Today") {
        // console.log("Today: ", endsDate)
        setStartDate(endsDate);
      }
      if (btnName == "This Week") {
        date.setUTCDate(date.getUTCDate() - 7);

        const last7Days = `${date.getUTCFullYear()}/${(date.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}/${date
          .getUTCDate()
          .toString()
          .padStart(2, "0")}`;

        // console.log("Last 7 day: ", last7Days)
        setStartDate(last7Days);
      }
      if (btnName == "This Month") {
        date.setUTCDate(date.getUTCDate() - 30);

        const last7Days = `${date.getUTCFullYear()}/${(date.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}/${date
          .getUTCDate()
          .toString()
          .padStart(2, "0")}`;
        // console.log("Last 30 day: ", last7Days)
        setStartDate(last7Days);
      }
    }
  }, [btnName]);


  return (
    <>
      <SeriviceHeadTabs />
      <CardTray>
        {topCardArray.map((card, index) => (
          <OverviewTopCard
            key={`${index}Card`}
            text={card.text}
            icon={card.icon}
            link={card.link}
            disabled={card.disabled}
          />
        ))}
      </CardTray>
      <HeaderBtnContainer>
        <TopBtnsContainer>
          <Btn
            isClicked={btnName === "day" ? true : false}
            onClick={() => {
              setbtnName("day");
              setIndicatorLeft(0);
            }}
          >
            Today
          </Btn>
          <Btn
            isClicked={btnName === "week" ? true : false}
            onClick={() => {
              setbtnName("week");
              setIndicatorLeft("8");
            }}
          >
            This Week
          </Btn>
          <Btn
            isClicked={btnName === "month" ? true : false}
            onClick={() => {
              setbtnName("month");
              setIndicatorLeft("18");
            }}
          >
            This Month
          </Btn>
          {/* <Btn
            isClicked={btnName === "Custom" ? true : false}
            onClick={() => {
              setbtnName("Custom");
              setIndicatorLeft("26.25");
            }}
          >
            Custom
          </Btn> */}
        </TopBtnsContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            marginTop: "-2.7rem",
          }}
        ></div>
      </HeaderBtnContainer>
      <Layout>
        <LeftLayoutPanel>
          <RightLayoutPanel>
            <AmountPanel days={7} />
            <SettlementPanel />
          </RightLayoutPanel>
          <GraphArea />
        </LeftLayoutPanel>
        <BottomLayoutPanel ref={ref}>

            <Channels filter={btnName} startDate={startDate} endDate={endDate} />
            <PaymentLink />
            <RecentTransactions startDate={startDate} endDate={endDate} />
            <PendingSettlement />

        </BottomLayoutPanel>
      </Layout>
    </>
  );
};

export default Overview;

import { combineReducers } from "redux";
import {
  businessesLoading,
  businessesReducer,
  businessKycReducer,
  businessReducer,
} from "./businessReducer";
import { settingsReducer } from "./settingsReducer";
import { serviceReducer } from "./serviceReducer";
import { userReducer, currentUserReducer, userOtpReducer } from "./userReducer";
import { liveReducer } from "./liveReducer";
import { logoutReducer } from "./logoutReducer";
import { questionReducer } from "./questionsReducer";
import { kycStartReducer } from "./kycStartReducer";
import { kycBoardingRecReducer } from "./boardingReducer";

import { ActionTypes } from "store/constants/actionType";

const appReducer = combineReducers({
  businesses: businessesReducer,
  business: businessReducer,
  businessKyc: businessKycReducer,
  users: userReducer,
  settings: settingsReducer,
  loading: businessesLoading,
  service: serviceReducer,
  live: liveReducer,
  logout: logoutReducer,
  currentUser: currentUserReducer,
  userOtp: userOtpReducer,
  questionData: questionReducer,
  kycStart: kycStartReducer,
  kycBoardingRec: kycBoardingRecReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === ActionTypes.LOGOUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

// const reducers = reducersNormal;

export default rootReducer;

import React, { useEffect, useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import {
  Container,
  FormContainer,
  Image,
  SubTitle,
  TitleCase,
  FormBody,
  ButtonContainer,
  Back,
  ActionButton,
  FormElement,
  ImageContainer,
  Input,
  InputGroup,
  InputLabel,
  FromGroup,
  AddTeamForm,
  Select,
  RowContainer,
  CalendarContainer,
  Calendar,
  Header,
} from "./styles";
import { Form, GradientLoader, Icon } from "shared/components";
import RightPic from "App/assets/images/addteam.svg";
import api from "shared/utils/api";
import consoleLog from "shared/utils/consoleLog";
import toast from "shared/utils/toast";
import Preloader from "shared/components/preloader";
import { boolean } from "yup";
import {
  FieldError,
  TrashBtn,
  UploadBox,
  UploadButton,
  UploadContainer,
  UploadInput,
  UploadLabel,
  UploadedFile,
} from "KYC-refactored/Gateway/UploadDocs/Styles";
import { shortenUrl } from "shared/utils/ellipsis";
import { uploadFile } from "apiServices/authService";
import MandatesClass from "../../mandateClass";
import { copyToClipboard } from "shared/utils/browser";
import { useParams } from "react-router-dom";

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0");
const day = String(currentDate.getDate()).padStart(2, "0");
const formattedDate = `${year}-${month}-${day}`;

const CreateForm = () => {
  const params: any=useParams();
  const kycBoardingRec = useSelector(
    (store: RootStateOrAny) => store.kycBoardingRec
  );
  const business = useSelector((store: RootStateOrAny) => store.business);
  const service = useSelector((store: RootStateOrAny) => store.service);

  const [loadingBanks, setLoadingBanks] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);
  const [uploading, setUploading] = useState(false);

  const [banks, setBanks] = useState([]);

  const [selectedBank, setSelectedBank] = useState<any>({
    institutionCode: "",
    code: "",
    value: "",
    label: "",
  });
  const [expiryDate, setExpiryDate] = useState(
    kycBoardingRec?.expiryDate ?? ""
  );
  const [payerAddress, setPayerAddress] = useState(
    kycBoardingRec?.payerAddress ?? ""
  );
  const [frequency, setFrequency] = useState(
    kycBoardingRec?.payerAddress ?? ""
  );

  const [isFromFront, setIsFromFront] = useState(false);
  const [payerName, setPayerName] = useState("");
  const [email, setEmail] = useState("");
  const [accNum, setAccNum] = useState("");
  const [bank, setBank] = useState("");
  const [narration, setNarration] = useState("");
  const [gender, setGender] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [phoneNo, setPhoneNo] = useState<string>("");
  const [signature, setSignature] = useState<string>("");
  const [uploadError, setUploadError] = useState<string>("");
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [loadingList, setLoadingList] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState(false);

  const initialValues = {
    email,
    selectedBank,
    expiryDate,
    payerAddress,
    payerName,
    accNum,
    narration,
    gender,
    amount,
    phoneNo,
    signature,
    frequency,
  };

  const frequencies = {
    weekly: "WEEKLY",
    monthly: "MONTHLY",
    threely: "3 MONTHS",
    sixly: "6 MONTHS",
    yearly: "YEARLY",
  };

  const categoryOptions = Object.values(frequencies).map((freq) => ({
    value: freq,
    label: freq,
  }));

  const testMobileNumber = (val: string) => {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      val
    );
  };

  const testEmail = (val: string) => {
    return /.+@.+\..+/.test(val);
  };


  const handleLinkCopy = () => {
    const url="https://app.fountainpay.ng/authenticate/mandate/create-mandate/"+btoa(service?.id)
    let useString = url && url.trim() !== "" ? url : " ";
    copyToClipboard(useString);
    toast.success("Copied");
  };

  const onSubmit = async (values: any) => {
    // /Endpoints needed...
    consoleLog('data ', selectedBank);
    setIsLoading(true);
    
    try {
      let InviteTeamMember = {
        start_date: formattedDate,
        end_date: expiryDate,
        account_no: accNum,
        institution_code: selectedBank?.institutionCode,
        bank_code: selectedBank?.code,
        payer_address: payerAddress,
        frequency: frequency,
        email: email,
        narration: narration,
        payer_name: payerName,
        gender: gender,
        amount: amount,
        phone_no: phoneNo,
        // signature: signature
      };
      const res:any = await MandatesClass.createMandate(InviteTeamMember);
      if(res){
        toast.success(res.message)
      }
    } catch(error:any) {
      consoleLog(error);
      toast.error(error);
    }finally{
      setIsLoading(false);
    }
  };

  const getBankList = async () => {
    setLoadingBanks(true);
    try {
      const res = await MandatesClass.getBanks();
      const banks = res.filter((x: any) => x.code && x.code !== "");
      // consoleLog("Banks without bank code: ", banks);
      // consoleLog("bank list", banks);
      const bankList: any = Object.values(banks).map((role: any) => ({
        value: role.id,
        label: role.name,
        code: role.code,
        institutionCode: role.institutionCode,
      }));
      setBanks(
        bankList.sort((a: any, b: any) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        )
      );
      setLoadingBanks(false);
    } catch (error) {
      // @ts-ignore
      //toast.error(error);
      consoleLog("bank ", error);
      
    }finally{
      setLoadingBanks(false);
    }
  };

  const validateAccountNo=async()=>{
    setValidating(true);
    try{
      
      console.log("Select bank: ", selectedBank)
      const { status, message, payload }: any = await api.post(
        "/checkout/bank/verify-account",
        {
          account_number: accNum,
          institution_code: selectedBank.institutionCode,
          bank_code: selectedBank.code,
        }
      );
      

      if (status && payload.accountName) {
        setPayerName(payload.accountName);
        setError(false);
      } else {
        setError(true);
        toast.error(message);
      }
    }catch(err: any){
      setError(true);
    }finally{
      setValidating(false)
    }
  }

  const handleSignatureUpload = async (files: any) => {
    // // console.log("selected file", files[0]);
    if (files[0].size > 10000000) {
      return toast.error(
        "Please slected signature should have a maximum size of 10MB."
      );
    }

    try {
      setUploading(true);

      let formData = new FormData();
      formData.append("certificate", files[0]);
      const response: any = await uploadFile(formData);
      if (response.status) {
        //// console.log(response);
        const cacUrl = response.payload?.fileUrl;
        // omniSaver("cac", cacUrl);
        setSignature(cacUrl);
        setUploading(false);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      setUploading(false);
      // consoleLog(error.toString());
    }
  };

  useEffect(() => {
    getBankList();
  }, []);

  useEffect(()=>{
		if(params && params?.id){
			setIsFromFront(true)
		}
	}, [params])

  useEffect(()=>{

		if(bank && accNum && accNum.length>=9){
      const selected: any=banks.find((x:any)=>x.value==bank);
      setSelectedBank({...selected});
			validateAccountNo()
		}
	}, [bank, accNum])

  if (loadingList) {
    return <GradientLoader />;
  }

  return (
    <Container>
      <FormContainer fromAuth={isFromFront}>
        <Header>
          <div>
            <TitleCase>Create mandate</TitleCase>
            <SubTitle>Create a mandate for fund transfer...</SubTitle>
          </div>
          {!isFromFront &&
            <div style={{cursor: "pointer"}} onClick={()=>handleLinkCopy()}>
              <Icon type="copy" size="20" color="green" /> share link
            </div>
          }
          
        </Header>

        <FormBody>
          <AddTeamForm>
            <Form
              enableReinitialize
              initialValues={initialValues}
              validations={{
                email: Form.is.required(),
                role: Form.is.required(),
              }}
              onSubmit={onSubmit}
              validate={() => {}}
              validateOnBlur={false}
            >
              <FormElement>
                <Form.Field.Select
                  name="bank"
                  label="Bank"
                  options={banks}
                  disable={loadingBanks}
                  onChange={(e: any) => setBank(e)}
                />
                <RowContainer>
                  <Form.Field.Input
                    name="account_no"
                    label="Account Number"
                    value={accNum}
                    onChange={(e: any) => setAccNum(e)}
                  />

                  <Form.Field.Input
                    name="amount"
                    label="Max Amount"
                    value={amount}
                    onChange={(e: any) => setAmount(e)}
                  />
                </RowContainer>

                <Form.Field.Input
                  name="narration"
                  label="Narration"
                  value={narration}
                  onChange={(e: any) => setNarration(e)}
                />
                <RowContainer>
                  <Form.Field.Input
                    name="expiryDate"
                    label="Termination Date"
                    value={expiryDate}
                    type="date"
                    min={"2022-08-24"}
                    pattern={"d{4}-d{2}-d{2}"}
                    onChange={(e: any) => setExpiryDate(e)}
                  />
                  <Form.Field.Select
                    name="frequency"
                    label="Frequency"
                    options={categoryOptions}
                    onChange={(e: any) => setFrequency(e)}
                  />
                </RowContainer>

                <hr />
                <Form.Field.Input
                  name="payerName"
                  label="Payer Name"
                  value={payerName}
                  disable={true}
                  readonly
                  onChange={(e: any) => setPayerName(e)}
                />
                {error && <span style={{color: 'red', fontSize:'12px'}}>Invalid account information</span>}

                <Form.Field.Input
                  name="payerAddress"
                  label="payer Address"
                  value={payerAddress}
                  onChange={(e: any) => setPayerAddress(e)}
                />
                <Form.Field.Input
                  name="email"
                  label="Email address"
                  value={email}
                  onChange={(e: any) => setEmail(e)}
                />
                <RowContainer>
                  <Form.Field.Input
                    name="gender"
                    label="Gender"
                    value={gender}
                    onChange={(e: any) => setGender(e)}
                  />
                  <Form.Field.Input
                    name="phoneNo"
                    label="Phone Number"
                    value={phoneNo}
                    onChange={(e: any) => setPhoneNo(e)}
                  />
                </RowContainer>
                
              </FormElement>
            </Form>
          </AddTeamForm>
        </FormBody>

        <ActionButton
          onClick={onSubmit}
          type="submit"
          variant="primary"
          isWorking={isloading}
          disabled={error || isloading}
        >
          Create
        </ActionButton>
      </FormContainer>
    </Container>
  );
};

export default CreateForm;

import styled from "styled-components";

export const TableRowContainer = styled.tr`
  background: #ffffff;
  height: 50px;
  border-bottom: 0.5px solid rgba(130, 130, 130, 0.9);
`;

export const TableLinkRowContainer = styled.tr`
  background: #ffffff;
  height: 58px;
  border-bottom: 0.2px solid rgba(130, 130, 130, 0.4);
  cursor: pointer;
  font-size: 14.7px !important;
  text-align: left;
  color: #999999;
  &:hover {
    background-color: #eff3f5;
    td{
      color: #000;
    }
  }
`;

export const TableLinkTdwContainer = styled.td`
  text-align: left;
  padding: 10px 10px;
  &:nth-child(1) {
    padding-left: 1.4em;
  }
`;

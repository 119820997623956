import { RootStateOrAny, useSelector } from "react-redux";
import { AmountSummary, MainAmount, MainNumber, PanelTitle } from "./Style";
import { useEffect, useState } from "react";
import api from "shared/utils/api";
import { TotalAmount } from "../../Agency/Transactions/Styled";
import { abbrNum } from "shared/utils/utility";
import consoleLog from "shared/utils/consoleLog";
import { formatMoney__ } from "shared/utils/moneyFormat";
import Spinner from "shared/components/spinner/spinner";

const AmountPanel = (props: any) => {
  const [days, setDays] = useState<string>("7");
  const [summary, setsummary] = useState<any>({});
  const service = useSelector((store: RootStateOrAny) => store.service);

  const [isloading, setisloading] = useState(false);
  const [isDatafetched, setisDatafetched] = useState(false);
  const { todayTransaction, weeklyTransaction, monthlyTransaction } = summary;
  const transactionsCount: number =
    todayTransaction?.totalTransaction +
    weeklyTransaction?.totalTransaction +
    monthlyTransaction?.totalTransaction;

  const getSummary = async () => {
    setisloading(true);
    try {
      const response = await api.get(`/services/${service?.id}/today-summary?days=${days}`);

      // consoleLog(response, "summaryResponse");
      if (response) {
        setsummary(response?.payload);
        setisDatafetched(true);
      } else {
        setisDatafetched(true);
      }
    } catch (err) {
      consoleLog(err);
    }finally{
      setisloading(false);
    }
  };

  useEffect(()=>{
    if(props.days){
      setDays(props.days);
      getSummary();
    }
  }, [props]);

  useEffect(() => {
    getSummary();

    // console.log("type testing", typeof transactionsCount);
  }, []);
  return (
    <AmountSummary>
      {isloading && 
        <div className="overlay">
          <Spinner />
        </div>
      }
      {!isloading && 
        <div>
          <div className={"title"}>Total Transaction Today</div>
          <div>
            <MainAmount>
              <div className={"amount"}>{formatMoney__(todayTransaction?.totalVolume) ?? "0"}</div>
              <div className={"title"}>
                {abbrNum(transactionsCount, 1) ?? "0"} transactions
              </div>
            </MainAmount>
            <MainNumber>
              <div className={"main"}>
                {abbrNum(weeklyTransaction?.totalVolume, 1) ?? "0"}
              </div>
              <div className={"title"}>This week</div>
            </MainNumber>
            <MainNumber>
              <div className={"main"}>
                {abbrNum(monthlyTransaction?.totalVolume, 1) ?? "0"}
              </div>
              <div className={"title"}>This month</div>
            </MainNumber>
          </div>
        </div>
      }
    </AmountSummary>
  );
};

export default AmountPanel;

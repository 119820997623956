import React, { useState } from 'react'
import {
	TableRowContainer,
	TableLinkRowContainer,
	TableLinkTdwContainer,
} from './Style'
import consoleLog from 'shared/utils/consoleLog'
import TdwContainer from './TdwContainer'
interface Props{
	headData: any;
	data?: any;
	hoverLink?: any
	clickFunction?: any;
	serialNumber?: boolean;
	index?: any;
  }
// interface RowProps {}
const TableRow = ({
	data,
	headData,
	hoverLink,
	clickFunction,
	serialNumber,
	index,
}: Props) => {
	const Container = hoverLink ? TableLinkRowContainer : TableRowContainer

	const [showMore, setShowMore] = useState()

	const hoverClick = (elm: any) => {
		if (hoverLink) {
			// consoleLog(elm);
		}
	}

	return (
		<Container>
			{serialNumber && (
				<TableLinkTdwContainer
					key={`sn${index}`}
					onClick={() => clickFunction && clickFunction(data)}>
					{++index}
				</TableLinkTdwContainer>
			)}
			{headData?.map((item: any, index: any) => {
				//// console.log('item:', item)
				let showMore = false
				return (
					<TdwContainer
						data={data}
						item={item}
						key={item.id}
						onClick={() => clickFunction && clickFunction(data)}
					/>
				)
			})}
		</Container>
	)
}

export default TableRow

import { Calendar, CalendarContainer } from "./styles";
import { useState } from "react";

type Props={
    handleSelection: Function
}

const today = new Date();
today.setUTCDate(today.getUTCDate() - 7);

const last7Days = `${today.getUTCFullYear()}-${(today.getUTCMonth() + 1).toString().padStart(2, '0')}-${today.getUTCDate().toString().padStart(2, '0')}`;

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0");
const day = String(currentDate.getDate()).padStart(2, "0");
const formattedDate = `${year}-${month}-${day}`;



const Daterange=(props: Props)=>{
    const [fromDate, setfromDate] = useState<string>(last7Days);
    const [toDate, settoDate] = useState<string>(formattedDate);

    const getDate=(value: string)=> {
        const today = new Date(value);
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        // console.log(`Formatted: ${year}-${month}-${date}`)
        return `${year}-${month}-${date}`;
    }
    const handleStartDate=async(value: string)=>{
        let date=await getDate(value);
        // console.log(`Start Date: ${value}`)
        setfromDate(value);
        props.handleSelection(date, toDate);
    }

    const handleEndDate=async(value: string)=>{
        let date=await getDate(value);
        // console.log(`End Date: ${value}`)
        settoDate(value);
        props.handleSelection(fromDate, date);
    }

    const handleDateSelection=()=>{
        // console.log(`Date range: ${fromDate} ${toDate}`)
        props.handleSelection(fromDate, toDate);
    }
    
    return(
        <CalendarContainer>
            <Calendar>
                From:
                <input
                    value={fromDate}
                    type="date"
                    min={"2022-08-24"}
                    max={formattedDate}
                    pattern={"\d{4}-\d{2}-\d{2}"}
                    onChange={(e: any) => {
                        handleStartDate(e.target.value);
                        //handleDateSelection();
                    }}
                />
            </Calendar>

            <Calendar>
            To:
                <input
                    value={toDate}
                    max={formattedDate}
                    type="date"
                    pattern={"\d{4}-\d{2}-\d{2}"}
                    onChange={(e: any) => {
                        handleEndDate(e.target.value);
                        //handleDateSelection()
                    }}
                />
            </Calendar>
        </CalendarContainer>
    )
}

export default Daterange;

import React, { useState, useEffect } from "react";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import { Icon, PageOverlay } from "shared/components";
import SuccessIcon from "App/assets/images/icons/round-success.svg";
import FailureIcon from "App/assets/images/icons/round-failure.svg";
import CheckSuccess from "App/assets/images/icons/check-success.svg";
import Tablet from "App/assets/images/icons/tablet.svg";
import ErrorTag from "App/assets/images/icons/error.svg";
import RefreshIcon from "App/assets/images/icons/refresh.svg";
import ReactSpeedometer from "react-d3-speedometer";
import api from "shared/utils/api";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import { useHistory, useParams } from "react-router-dom";

import {
  ActionButton,
  Back,
  ButtonContainer,
  LeftContainer,
  RightContainer,
  Container,
  LSection,
  RSection,
  TitleCase,
  SubContainer,
  LeftTitle,
  LeftValue,
  LeftDiv,
  RightDiv,
  RightTitle,
  RightValue,
  ButtonDiv,
  RightTopContainer,
  LeftTopContainer,
  InnerRight,
  RightTopInner,
  RightTopInnerRem,
  Divider,
  MiniChartContainer,
  MiniChartSection,
  MiniItem,
  MiniItemSection,
  MiniItemSectionUp,
  MiniItemSectionDown,
  LogSectionContainer,
  LogSection,
  LogSectionLeft,
  LogSectionRight,
  IconImage,
  LeftRightIcon,
  RoundClock,
  ClockSpentMessage,
  MinutesDiv,
  LeftTitleAmount,
  LeftTitleRevalidate,
  SettlementDiv,
  Title,
  Content,
} from "./Style";
import { copyToClipboard } from "shared/utils/browser";
import toast from "shared/utils/toast";
import EmptyState from "shared/components/FreeComp/EmptyState";
import { GradientLoader } from "shared/components";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { currencyFormat100, maskString } from "shared/utils/moneyFormat";
import { getStatusColor } from "shared/utils/statusColor";
import consoleLog from "shared/utils/consoleLog";
import { differnceTime, getOnlyTime } from "shared/utils/dateTime";
import Table from "shared/components/Table";
import { ModeSpan } from "Services/AccountDetails/Styles";
import PageIndicator from "shared/components/PageIndicator";

const TransactionDetails = () => {
  // { obj, setPageView }: any

  const [active, setActive] = useState(0);
  const [data, setData] = useState({} as any);
  const [settlemtdata, setSettlemtdata] = useState([] as any)
  const [customerdata, setCustomerdata] = useState([] as any)
  const [isLinkCopied, setLinkCopied] = useState(false);
  const [isRevalidated, setRevalidated] = useState(false);
  const [isDownload, setDownload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isRevalidating, setIsRevalidating] = useState(false);
  const service = useSelector((store: RootStateOrAny) => store.service);
  const business = useSelector((store: RootStateOrAny) => store.business);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const history = useHistory();
  let { id: transId }: any = useParams();
  //   let obj: any = {};
  // let { id }: any = useParams();
  // if (!id) return null;

  // let obj: any = {};
  // consoleLog(obj, "obj");

  const getTransactionDetails = async () => {
    try {
      const response = await api.get(
        `/services/${service?.id}/transactions/${transId}`
      );

      consoleLog(response, "respTransDet");

      if (
        response.status == true &&
        response.payload &&
        response.payload instanceof Object
      ) {
        let respObj = response.payload;
        respObj.remitedAmount =
          respObj.remitedAmount &&
          `${currencyFormat100(respObj.remitedAmount)}`;
        respObj.senderName = respObj?.Customer?.fullName;
        respObj.email = respObj?.Customer?.email;
        respObj.amountPaid = respObj.amountPaid ==null? `${currencyFormat100(respObj.amount)}` : `${currencyFormat100(respObj.amountPaid)}`;
        respObj.amount = respObj.amount && `${currencyFormat100(respObj.amount)}`;
        respObj.charge = respObj.charge && `${currencyFormat100(respObj.charge)}`;
        respObj.origStatus = respObj.status;
        respObj.deviceType = respObj?.transactionDetails?.meta.deviceType ?? respObj?.deviceType;
        respObj.status = respObj.status &&
          (respObj.status.toLowerCase().includes("init") ? (
            <div style={{ color: getStatusColor("pending") }}>
              In progress
            </div>
          ) : (
            <div style={{ color: getStatusColor(respObj?.status) }}>
              {respObj?.status?.toLowerCase()[0].toUpperCase() +
                respObj?.status?.toLowerCase().substring(1)}
            </div>
          ));

        respObj.timeSpent = differnceTime(
          respObj?.createdAt,
          respObj?.updatedAt
        );

        respObj.updatedAt =
          respObj?.updatedAt &&
          new Date(respObj?.updatedAt).toLocaleString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
        
        //fix settlement information
        // await getSettlementDetails(respObj?.settlementId);
        
        
        
        setData(respObj);
        setCustomerdata([{
          name: respObj?.Customer?.fullName, 
          email: respObj?.Customer?.email,
          phone: respObj?.Customer?.phone
        }])
      } else {
        toast.error("Unable to fetch Transaction Details");
      }

      //consoleLog(response, "responseServ");
    } catch (err) {
      // @ts-ignore
      toast.error("Unable to fetch Transaction Details");
      // console.log(err)
      // toast.error(err && err.detail);
    } finally {
      setLoading(false);
      
    }
  };

  const getSettlementDetails=async()=>{
    const response = await api.get(
      `/client/business/settlements/${data?.settlementId}`
    );

    // consoleLog("Settlement details: ", response);
    if(response?.status){
      const settlement= response?.payload?.SettlementHistory instanceof Object  && response?.payload?.SettlementHistory.map((items: any, i: number)=>{
        return(
          {
            updatedAt:new Date(items?.updatedAt).toLocaleString("en-GB", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
            createdAt:items?.createdAt,
            settledStatus: (items.status.toLowerCase().includes("init") ? (
              <div style={{ color: getStatusColor("pending") }}>
                In progress
              </div>
            ) : (
              <div style={{ color: getStatusColor(items?.status) }}>
                {items?.status?.toLowerCase()[0].toUpperCase() +
                  items?.status?.toLowerCase().substring(1)}
              </div>
            )),
            accountNumber: items?.BankAccount?.accountNumber,
            accountName: items?.BankAccount?.bankAccountName,
            bankName: items?.BankAccount?.bank.name,
            bankCode: items?.BankAccount?.bank.code,
            institutionCode: items?.BankAccount?.bank.instituitionCode,
            amount: `${currencyFormat100(items?.amount, data?.paymentCurrency)}`,
            // charge: data?.charge
          }
        )
      })
      // console.log("Resp object: ", settlement)
      setSettlemtdata(settlement)
    }
    // setSettlemtdata([{
    //   updatedAt:"" ,
    //   settledStatus: respObj?.settledStatus? 'Settled': 'Pending' ,
    //   accountNumber: "",
    //   amount: respObj?.remitedAmount,
    //   charge: respObj?.charge
    // }])
    // consoleLog(respObj, "respObj");
  }

  useEffect(() => {
    getTransactionDetails();
  }, []);

  useEffect(() => {
    getSettlementDetails();
  }, [data]);

  useEffect(() => {
    if (isDownload) setDownload(false);
  }, [isDownload]);

  if (loading) {
    return <GradientLoader />;
  }

  let obj = data;
  const handleLinkCopy = (link: any) => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
    copyToClipboard(link || window.location.href);
    toast.success("Copied to clipboard!");
  };
  const handleRevalidate = async (id: string) => {
    setIsRevalidating(true)
    try {
      const response = await api.get(`/checkout/revalidate-payment/${id}`);

      consoleLog("Revalidate: ", response);

      if (
        response.status == true &&
        response.payload &&
        response.payload instanceof Object
      ) {
        getTransactionDetails();
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      // @ts-ignore
      consoleLog("Revalidate: ", err);
      toast.error("Unable to revalidate transaction.");
    } finally {
      setIsRevalidating(false);
    }
  };

  let leftTop = (
    <LeftTopContainer>
      <LeftTitleAmount>
        <div>Amount Paid</div>
        <LeftTitleRevalidate>
          {obj?.amountPaid}
        </LeftTitleRevalidate>
      </LeftTitleAmount>
      <LeftRightIcon>
        {String(data?.origStatus)
          .toLowerCase()
          .indexOf("success") > -1 && (
          <>
            <Icon type="check_circle" size={20} />
            <div>Success</div>
          </>
        )}
        {obj?.origStatus.toUpperCase() == "PENDING" ||
          (obj?.origStatus.toUpperCase() == "FAILED" && (

            <p style={{cursor: 'pointer', color:'#FF2424'}} onClick={() => handleRevalidate(obj?.reference)}>
              <Icon type="repeat" size={20} color={'#FF2424'} />
              <span>Revalidate</span>
            </p>
        ))}
      </LeftRightIcon>
    </LeftTopContainer>
  );

  let rightTop = (
    <RightTopContainer>
      <RightTopInner onClick={() => setActive(0)} active={active === 0}>
        Analytics
      </RightTopInner>
      <RightTopInner onClick={() => setActive(1)} active={active === 1}>
        Refunds
      </RightTopInner>
      <RightTopInnerRem />
    </RightTopContainer>
  );

  let arrLeft = [
    { id: "mode", label: "Mode" },
    { id: "reference", label: "Transaction ID" },
    { id: "gatewayChannel", label: "Channel" },
    { id: "amount", label: "Amount" },
    { id: "remitedAmount", label: "Amount Settled" },
    { id: "charge", label: "Charge" },
    { id: "updatedAt", label: "Paid At" },
    { id: "narration", label: "Narration" },
    { id: "remark", label: "Remark" },
    { id: "status", label: "Status" },
  ];

  let leftSide = arrLeft.map((el: any, idx) => {
    return (
      <LeftDiv key={`L${idx}`}>
        <LeftTitle>{el.label}</LeftTitle>
        {el.id=='mode' ?( 
          <ModeSpan mode={obj[el.id] == "LIVE" ? true : false}>
            {obj[el.id]}
          </ModeSpan> 
        ):(
        ["transactionId", "reference"].includes(el.id) ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LeftValue>{obj[el.id]}</LeftValue>
            <p
              style={{
                color: "#219653",
                marginLeft: "8px",
                fontSize: "14px",
                cursor: "pointer",
              }}
              onClick={() => handleLinkCopy(obj[el.id])}
            >
              Copy
            </p>
          </div>
        ) : (
          <LeftValue>{obj[el.id]}</LeftValue>
        ))}
      </LeftDiv>
    );
  });

  let arrCard = [
    { id: "cardType", label: "Card Type" },
    { id: "country", label: "Bank and Country" },
    { id: "senderName", label: "Sender's Name" },
    { id: "cardNumber", label: "Card Number" },
    { id: "IPAddress", label: "IP Address" },
    { id: "transactionIdentifier", label: "Authorization" },
  ];

  let arrDirectDebit = [
    { id: "bankName", label: "Bank Name" },
    { id: "country", label: "Country" },
    { id: "sendersName", label: "Sender's Name" },
    { id: "IPAddress", label: "IP Address" },
    { id: "sendersAccountNumber", label: "Sender's Account" },
    { id: "transactionIdentifier", label: "Authorization" },
  ];

  const settlementcolumnHeaders = [
    { id: "updatedAt", label: "Date" },
    { id: "settledStatus", label: "Status" },
    { id: "amount", label: "Amount" },
    { id: "bankName", label: "Bank" },
    { id: "accountNumber", label: "Account" },
    { id: "accountName", label: "Account Name" },
  ];

  const customercolumnHeaders = [
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" }
    
  ];

  let arrRight = () => {
    let arr = [
      {
        id: "direct_debit",
        attr: "directDebitDetails",
        useArr: arrDirectDebit,
      },
      {
        id: "card",
        attr: "cardDetails",
        useArr: arrCard,
      },
    ];

    for (let el of arr) {
      if (
        obj?.paymentMode &&
        String(obj?.paymentMode)
          ?.toLowerCase()
          .trim() === el?.id
      ) {
        return [el?.useArr, el?.attr];
      }
    }

    return [arrCard, "cardDetails"];
  };

  let otherObj: object = {}; //other items for summaryArray
  let otherArr: Array<object> = []; //other items for summaryArray

  let rightSide = () => {
    let arr: any = arrRight();
    let useObj = obj?.transactionDetails?.meta && obj?.transactionDetails?.meta[arr[1]];
    if (!useObj || !(useObj instanceof Object)) return null;
    let useArr = arr[0];
    useObj.transactionIdentifier = obj?.transactionDetails?.meta?.transactionIdentifier ?? "";
    useObj.senderName = data?.senderName;
    otherObj = { ...otherObj, ...useObj };
    //consoleLog(otherObj, "otherObj");
    return useArr.map((el: any, idx: number) => {
      if (el.id === "cardNumber") {
        useObj[el.id] = maskString(useObj[el.id]);
      }
      if (el.id === "sendersAccountNumber") {
        useObj[el.id] = maskString(useObj[el.id], 2, 3);
      }
      otherArr.push({ id: el.id, label: el.label });
      return (
        <RightDiv key={`L${idx}`}>
          <RightTitle>{el.label}</RightTitle>
          <RightValue>{useObj[el.id]}</RightValue>
        </RightDiv>
      );
    });
  };

  let rightSideFn = rightSide();

  let arrMiniChart = [
    { id: "deviceType", label: "Device Type", icon: Tablet },
    { id: "attempts", label: "Attempts", icon: RefreshIcon },
    { id: "errors", label: "errors", icon: ErrorTag },
  ];
  let rightMiniChart: any = arrMiniChart.map((el: any, idx: number) => {
    return (
      <MiniItem key={`CH${idx}`}>
        <MiniItemSection>
          <img src={el.icon} />
        </MiniItemSection>
        <MiniItemSection>
          <MiniItemSectionUp>{el.label}</MiniItemSectionUp>
          <MiniItemSectionDown>{obj[el.id]}</MiniItemSectionDown>
        </MiniItemSection>
      </MiniItem>
    );
  });

  let rightChartSide = (
    <MiniChartContainer>
      <MiniChartSection>
        <RoundClock>
          {data?.timeSpent} <MinutesDiv>MINUTES</MinutesDiv>
          <ClockSpentMessage>SPENT ON PAGE</ClockSpentMessage>
        </RoundClock>
      </MiniChartSection>
      <MiniChartSection style={{ marginLeft: 5 }}>
        {rightMiniChart}
      </MiniChartSection>
    </MiniChartContainer>
  );

  let refundArray = [
    { id: "refund", label: "Amount Refunded" },
    { id: "refund", label: "Refund created at" },
    { id: "refund", label: "Refund initiated by" },
    { id: "refund", label: "Reason for refund" },
  ];

  let refundComp1 = refundArray.map((el: any, idx) => {
    return (
      <LeftDiv key={`Ref${idx}`}>
        <LeftTitle>{el.label}</LeftTitle>
        <LeftValue>{obj[el.id]}</LeftValue>
      </LeftDiv>
    );
  });

  let refundComp2 = <EmptyState stateTitle="No Refund" />;
  let refundComp = [refundComp1, refundComp2];
  let refundIndex = Math.floor(Math.random() * 2);

  let logArray = () => {
    return obj?.TransactionActivities &&
      obj.TransactionActivities instanceof Array
      ? obj.TransactionActivities.sort(
          (a: any, b: any) =>
            new Date(a?.createdAt).getTime() - new Date(b?.createdAt).getTime()
        ).map((el: any) => {
          let logObj: any = {};
          let chkValues = ["fail", "abandon", "cancel"];
          logObj.time = getOnlyTime(el?.updatedAt);
          logObj.stmt = el?.description;
          let status = "";
          for (let elm of chkValues) {
            if (
              el?.description &&
              el?.description
                .trim()
                .toLowerCase()
                .indexOf(elm.trim().toLowerCase()) > -1
            ) {
              status = "fail";
              break;
            }
          }

          logObj.status = status === "" ? "success" : status;
          return logObj;
        })
      : [];
  };

  let dispLog = logArray().map((el: any, idx: number) => {
    return (
      <LogSection key={`LOG${idx}`}>
        <LogSectionLeft>
          {el.time}
          {el.status === "success" && <IconImage src={SuccessIcon} />}
          {el.status === "fail" && <IconImage src={FailureIcon} />}
        </LogSectionLeft>
        <LogSectionRight>{el.stmt}</LogSectionRight>
      </LogSection>
    );
  });

  
  let summaryArray: any = [
    { id: "reference", label: "Reference" },
    { id: "updatedAt", label: "Transaction Date" },
    { id: "email", label: "Customer (email)" },
    { id: "amount", label: "Amount Paid" },
    ...otherArr,
  ];

  let summaryData = () => {
    let summaryObj = { ...obj, ...otherObj };
    let resArr: any = [];
    for (let el of summaryArray) {
      let itemArr: any = [];
      itemArr.push(el.label);
      itemArr.push(summaryObj[el.id] ?? "");
      resArr.push(itemArr);
    }
    return resArr;
  };

  if (!shouldPerform(getRoleName(currentUser, business?.id), "VTrans")) {
    history.push("/authenticate");
    return null;
  }
  
  return (
    
    <>
      {isRevalidating && 
        <PageOverlay />
      }
      <div style={{ marginLeft: -15, marginRight: -15 }}>
        <Container>
          <LeftContainer>
            <Content>
              {leftTop}
              {leftSide}
              {data?.gatewayChannel=='TRANSFER' && 
                <>
                  <h4>Transfer detail</h4>
                  <Content>
                    <LeftTitle>Recieving Account Information</LeftTitle>
                    <LeftDiv>
                      <LeftTitle>Account Number</LeftTitle>
                      <LeftValue>
                        {data?.transactionDetails?.meta?.init_status?.accountNumber}
                      </LeftValue>
                    </LeftDiv>

                    <LeftDiv>
                      <LeftTitle>Account Name</LeftTitle>
                      <LeftValue>{data?.transactionDetails?.meta?.init_status?.accountName}</LeftValue>
                    </LeftDiv>

                    <LeftDiv>
                      <LeftTitle>Bank:</LeftTitle>
                      <LeftValue>WEMA BANKS</LeftValue>
                    </LeftDiv>
                    {data?.transactionDetails?.meta?.success_status && 
                      <>
                        <h4>Source Account Information</h4>
                        <LeftDiv>
                          <LeftTitle>Payer bank:</LeftTitle>
                          <LeftValue>{data?.transactionDetails?.meta?.success_status?.bankname}</LeftValue>
                        </LeftDiv>
                        <LeftDiv>
                          <LeftTitle>Paid from:</LeftTitle>
                          <LeftValue>{data?.transactionDetails?.meta?.success_status?.originatoraccountnumber}</LeftValue>
                        </LeftDiv>
                        <LeftDiv>
                          <LeftTitle>Paid by:</LeftTitle>
                          <LeftValue>{data?.transactionDetails?.meta?.success_status?.originatorname}</LeftValue>
                        </LeftDiv>
                        <LeftDiv>
                          <LeftTitle>Session Id:</LeftTitle>
                          <LeftValue>{data?.transactionDetails?.meta?.success_status?.sessionid}</LeftValue>
                        </LeftDiv>
                        <LeftDiv>
                          <LeftTitle>Date/Time:</LeftTitle>
                          <LeftValue>{data?.transactionDetails?.meta?.success_status?.requestdate}</LeftValue>
                        </LeftDiv>
                      </>
                    }
                  </Content>
                </>
              }
              {data?.gatewayChannel=='E_BANKING' && 
                <SettlementDiv>
                  <Title>Transfer detail</Title>
                  <Content>
                    <LeftDiv>
                      <LeftTitle>Account Number</LeftTitle>
                      <LeftValue>{data?.transactionDetails?.meta?.initDetails?.originatorAccountNumber}</LeftValue>
                    </LeftDiv>

                    <LeftDiv>
                      <LeftTitle>Mandate Reference</LeftTitle>
                      <LeftValue>{data?.transactionDetails?.meta?.initDetails?.mandateReferenceNumber}</LeftValue>
                    </LeftDiv>

                    <LeftDiv>
                      <LeftTitle>Bank Institution Code:</LeftTitle>
                      <LeftValue>{data?.transactionDetails?.meta?.initDetails?.sourceInstitutionCode}</LeftValue>
                    </LeftDiv>
                  </Content>
                </SettlementDiv>
              }

            </Content>
            <Content>
              <Title>Settlement</Title>
              <>
                <Table
                  headData={settlementcolumnHeaders}
                  bodyData={settlemtdata ?? []}
                  hoverLink
                  serialNumber
                />
              </>
            </Content>

            <Content>
              <Title>Customer</Title>
              <>
                <Table
                  headData={customercolumnHeaders}
                  bodyData={customerdata ?? []}
                  hoverLink
                  serialNumber
                />
              </>
            </Content>
             
          </LeftContainer>
          

          <RightContainer>
          <Content>
              {rightTop}
              {active === 0 ? (
                <>
                  <RSection>{rightSideFn}</RSection>
                  <Divider />
                  {rightChartSide}
                  <LogSectionContainer>{dispLog}</LogSectionContainer>
                </>
              ) : (
                // <>{refundComp[refundIndex]}</>
                <>{refundComp2}</>
              )}
            </Content> 
            
          </RightContainer>
        </Container>
      </div>
    </>
  );
};

export default TransactionDetails;

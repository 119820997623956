export const ROLES = [];

export const MENUS = [
  // {
  //   title: "Gateway",
  //   margin: 35,
  //   type: true,
  //   category: "GATEWAY",
  // },
  {
    id: "overview",
    title: "Overview",
    icon: "home1",
    url: "/overview",
    type: false,
    category: "GATEWAY",
  },
  {
    id: "myApps",
    title: "My Apps",
    icon: "copy",
    url: "/my-apps",
    type: false,
    category: "GATEWAY",
  },
  {
    id: "customers",
    title: "Customers",
    icon: "user1",
    url: "/customers",
    type: false,
    category: "GATEWAY",
    permitted: "VCustomers",
  },
  {
    id: "chargeBacks",
    title: "Charge Back",
    icon: "arrow-left-circle",
    url: "/charge-backs",
    type: false,
    category: "GATEWAY",
    permitted: "MDisputes",
  },

  {
    id: "subAccounts",
    title: "Subaccount",
    icon: "card",
    url: "/sub-account",
    type: false,
    category: "GATEWAY",
    permitted: "VPaySplitSub",
  },

  // {
  //   id: "teams",
  //   title: "Team",
  //   icon: "team_teamwork_users_icon",
  //   url: "/team",
  //   type: false,
  //   category: "GATEWAY",
  //   permitted: "VTeams",
  // },

  // ======== Agency banking ========
  {
    title: "Agency Banking",
    margin: 35,
    type: true,
    category: "AGENCY",
  },
  {
    title: "Overview",
    icon: "home1",
    url: "/agency/overview",
    type: false,
    category: "AGENCY",
  },
  {
    title: "Agents",
    icon: "user1",
    url: "/agency/agents",
    type: false,
    category: "AGENCY",
  },
  {
    title: "Transactions",
    icon: "bar-chart",
    url: "/agency/transactions",
    type: false,
    category: "AGENCY",
  },
  // {
  //   title: "Settlements",
  //   icon: "stack",
  //   url: "/agency/settlements",
  //   type: false,
  //   category: "AGENCY",
  // },
  {
    title: "Pricing",
    icon: "tag",
    url: "/agency/pricing",
    type: false,
    category: "AGENCY",
  },

  {
    title: "Inventory",
    icon: "inventory",
    url: "/agency/inventory",
    type: false,
    category: "AGENCY",
  },
  {
    title: "Geolocation",
    icon: "location1",
    url: "/agency/geolocation",
    type: false,
    category: "AGENCY",
  },
  // {
  //   title: "Users",
  //   icon: "users",
  //   url: "/agency/users",
  //   type: false,
  //   category: "AGENCY",
  // },
];

export const APPMENUS=[
  {
    id: "overview",
    title: "Overview",
    icon: "home1",
    url: "/dashboard",
    type: false,
    category: "GATEWAY",
  },

  {
    id: "transactions",
    title: "Transactions",
    icon: "bar-chart",
    url: "/transactions",
    type: false,
    category: "GATEWAY",
  },

  {
    id: "settlements",
    title: "Settlements",
    icon: "card",
    url: "/settlements",
    type: false,
    category: "GATEWAY",
  },

  {
    id: "/paymentlinks",
    title: "Payment Links",
    icon: "link",
    url: "/payment-link-list",
    type: false,
    category: "GATEWAY",
  },

  {
    id: "directDebits",
    title: "Mandate Management",
    icon: "compass",
    url: "/mandate-management",
    type: false,
    category: "GATEWAY",
  },
]

export const USERS = [];

export const RESETREQUEST = [];

export const CLIENTLOGS = [];
